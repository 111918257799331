/** @format */

// ProjectOne.js
import React, { useState, useEffect } from "react";
import "../../styles/projectsJour.css";
import { Link } from "react-router-dom";
import TopNavBar from "../../components/topNav";
import Footer from "../../components/footer";
import Tumbnail from "../../assets/tumbNails/project1.jpg";

const imageContextPrewed = require.context(
  "../../assets/01 prasanth & priya/pre wed",
  false,
  /\.(jpg)$/
);
const imageUrlsPrewed = imageContextPrewed.keys().map(imageContextPrewed);

const imageContextPath2 = require.context(
  "../../assets/01 prasanth & priya/rec",
  false,
  /\.(jpg)$/
);
const imageUrlsPath2 = imageContextPath2.keys().map(imageContextPath2);

const imageContextPath3 = require.context(
  "../../assets/01 prasanth & priya/wed",
  false,
  /\.(jpg)$/
);
const imageUrlsPath3 = imageContextPath3.keys().map(imageContextPath3);

const allImageUrls = [...imageUrlsPrewed, ...imageUrlsPath2, ...imageUrlsPath3];


const ProjectOne = () => {
  useEffect(() => {
    document.documentElement.scrollTop = 0;
  }, []);

  return (
    <div>
      <TopNavBar />

      <div className="proj-gallery-container">
        <div className="header-project">
          <img
            src={Tumbnail}
            alt="Full Page"
            className="proj-full-page-image"
          />
        </div>

        <div className="proj-gallery">
          {allImageUrls.map((imageUrl, index) => (
            <Link to="#" key={index} className="proj-gallery-link">
              <img
                src={imageUrl}
                alt={`Photo ${index + 1}`}
                className="proj-gallery-image"
              />
            </Link>
          ))}
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default ProjectOne;
