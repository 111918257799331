/** @format */

import React from "react";
import "../styles/gallary.css";

const Gallery = () => {
  const imageContextPrewed = require.context(
    "../assets/gallary",
    false,
    /\.(jpg)$/
  );
  const imageUrlsPrewed = imageContextPrewed.keys().map(imageContextPrewed);

  const imageUrls = [...imageUrlsPrewed];

  return (
    <div className="photogrid-container-cap">
      <h1
        className="photogrid-heading-new"
        style={{
          color: "#b89043",
          fontFamily: "Heroliga",
          fontWeight: "bold",
          marginTop: "60px",
        }}
      >
        MOMENTS SHAPED
      </h1>
      <div className={`photogrid photogrid-mobile`}>
        {imageUrls.map((imageUrl, index) => (
          <div key={index} className="photogrid-item">
            <img src={imageUrl} alt={`Photo ${index + 1}`} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default Gallery;
