
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "../styles/bottomNav.css";
import logo from "../assets/logo/dreamLogo.png";
const BottomNavBar = ({ isVisible }) => {
  const [showMobileMenu, setShowMobileMenu] = useState(false);
  const navigate = useNavigate();
  const handleNavigation = (page) => {
    navigate(page);
  };
  const handleHamburgerClick = () => {
    setShowMobileMenu(!showMobileMenu);
  };
  useEffect(() => {
    let prevScrollPos = window.pageYOffset;
    const handleScroll = () => {
      const currentScrollPos = window.pageYOffset;
      const isVisible =
        prevScrollPos > currentScrollPos || currentScrollPos < 10;
      setShowMobileMenu(false); // Close mobile menu on scroll
      prevScrollPos = currentScrollPos;
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <div className={`bottom-nav-bar ${isVisible ? "visible" : ""}`}>
      <div className="nav-item-bottom empty-space" />
      {window.innerWidth <= 600 && (
        <div className="mobile-header">
          <div className="logo-container">
            <img src={logo} alt="Logo" className="logo-image" />
          </div>
          {/* <div className="hamburger-menu" onClick={handleHamburgerClick}>
            &#9776;
          </div> */}
        </div>
      )}
      {window.innerWidth <= 600 && showMobileMenu && (
        <div className="slider-menu-bottom-container">
          <div className="slider-menu-bottom-background"></div>
          <div className="slider-menu-bottom">
            <div
              className="close-button"
              onClick={() => setShowMobileMenu(false)}
            >
              &times;
            </div>
            <span
              className="slider-menu-bottom-item"
              onClick={() => handleNavigation("/")}
            >
              Home
            </span>
            <span
              className="slider-menu-bottom-item"
              onClick={() => handleNavigation("/ourStory")}
            >
              OUR STORY
            </span>
            <span
              className="slider-menu-bottom-item"
              onClick={() => handleNavigation("/portrayals")}
            >
              PORTRAYALS
            </span>
            <span
              className="slider-menu-bottom-item"
              onClick={() => handleNavigation("/films")}
            >
              FLIMS
            </span>
            <span
              className="slider-menu-bottom-item"
              onClick={() => handleNavigation("/critiques")}
            >
              CRITIQUE
            </span>
            <span
              className="slider-menu-bottom-item"
              onClick={() => handleNavigation("/contactUs")}
            >
              PROPOSALS
            </span>
          </div>
        </div>
      )}
      {window.innerWidth > 600 && (
        <>
          {/* Your existing code for desktop view */}
          <div
            className="nav-item-bottom"
            onClick={() => handleNavigation("/")}
          >
            HOME
          </div>
          <div
            className="nav-item-bottom"
            onClick={() => handleNavigation("/ourStory")}
          >
            OUR STORY
          </div>
          <div
            className="nav-item-bottom"
            onClick={() => handleNavigation("/portrayals")}
          >
            PORTRAYALS
          </div>
          <div className="nav-item">
            <img src={logo} alt="Logo" className="logo-image" />
          </div>
          <div
            className="nav-item-bottom"
            onClick={() => handleNavigation("/films")}
          >
            FILMS
          </div>
          <div
            className="nav-item-bottom"
            onClick={() => handleNavigation("/critiques")}
          >
            CRITICS
          </div>
          <div
            className="nav-item-bottom"
            onClick={() => handleNavigation("/contactUs")}
          >
            PROPOSALS
          </div>
        </>
      )}
      <div className="nav-item-bottom empty-space" />
    </div>
  );
};
export default BottomNavBar;
