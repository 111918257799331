/** @format */

import React, { useEffect, useState } from "react";
import Slider from "../components/slider";
import AboutPage from "./about";
import Gallery from "./gallary";
import VideoComponent from "../components/videoslid";
import InstagramPage from "../Pages/insta";
import Footer from "../components/footer";
import TopNavBar from "../components/topNav.js";
import ResponsiveSection from "../components/newSection";
import ServicesSection from "../components/ourservices";

const Home = () => {
  const [showTopNavBar, setShowTopNavBar] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollY = window.scrollY || document.documentElement.scrollTop;

      setShowTopNavBar(scrollY > 500);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    document.documentElement.scrollTop = 0;
  }, []);

  return (
    <div className="home-container" style={{ backgroundColor: "#f6f5f1" }}>
      <Slider />
      {showTopNavBar && <TopNavBar />}
      <div id="about" className="content">
        <AboutPage />
      </div>
      <div id="gallery" className="content">
        <Gallery />
      </div>

      <div id="newSection" className="content">
        <ResponsiveSection />
      </div>
      <div id="video" className="content">
        <VideoComponent />
      </div>

      <div id="services " className="content">
        <ServicesSection />
      </div>
      <div id="instagram" className="content">
        <InstagramPage />
      </div>
      <div id="footer" className="content">
        <Footer />
      </div>
    </div>
  );
};

export default Home;
