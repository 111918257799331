/** @format */

import React, { useState, useEffect } from "react";
import "../styles/journals.css";
import { Link } from "react-router-dom";
import TopNavBar from "../components/topNav";
import Footer from "../components/footer";
import image1 from "../assets/tumbNails/project1.jpg";
import image2 from "../assets/tumbNails/project2.jpg";
import image3 from "../assets/tumbNails/project3.jpg";
import image4 from "../assets/tumbNails/project4.jpg";
import image5 from "../assets/tumbNails/project5.jpg";
import image6 from "../assets/tumbNails/project6.jpg";
import image7 from "../assets/tumbNails/project7.jpg";
import image8 from "../assets/tumbNails/project8.jpg";

const imageUrls = [
  image1,
  image2,
  image3,
  image4,
  image5,
  image6,
  image7,
  image8,
];

const cityRoutes = [
  "/projectOne",
  "/projectTwo",
  "/ProjectThree",
  "/projectFour",
  "/projectFive",
  "/projectSix",
  "/projectSeven",
  "/projectEight",
];

const JournalsPage = () => {
  const [hoveredIndex, setHoveredIndex] = useState(null);

  useEffect(() => {
    const handleScroll = () => {
      const windowHeight = window.innerHeight;
      const scrollPosition = window.scrollY;
      const headerImageElement = document.getElementById("headerImage");

      if (headerImageElement) {
        const imageTop = 0;
        const imageBottom = windowHeight;
        const opacity =
          1 -
          Math.max(0, Math.min(1, (scrollPosition - imageTop) / windowHeight));

        headerImageElement.style.opacity = opacity.toString();
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    document.documentElement.scrollTop = 0;
  }, []);

  return (
    <div style={{ backgroundColor: "#fff" }}>
      <TopNavBar />

      <div className="journal-container">
        <div className="journal-section">
          <h2 className="journal-heading">Portrayals</h2>
          <p className="journal-content">
            They typically involve professionally taken photographs of the bride
            and groom either before, during, or after the wedding ceremony.
            These portraits often showcase the couple's love, emotions, and
            personalities while providing timeless keepsakes to cherish for a
            lifetime.
          </p>
        </div>
      </div>

      <div className="gallery-container">
        <div className="gallery">
          {imageUrls.map((imageUrl, index) => (
            <Link to={cityRoutes[index]} key={index}>
              <div
                className="jornal-item"
                onMouseEnter={() => setHoveredIndex(index)}
                onMouseLeave={() => setHoveredIndex(null)}
              >
                <img src={imageUrl} alt={`Photo ${index + 1}`} />
              </div>
            </Link>
          ))}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default JournalsPage;
