/** @format */

import React, { useState, useEffect } from "react";
import BottomNavBar from "../components/bottomNav.js";
import "../styles/slider.css";

const Slider = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [showBottomNavBar, setShowBottomNavBar] = useState(true);
  const [imagesLoaded, setImagesLoaded] = useState(false);
  const imagesWeb = [
    require("../assets/homeweb/JPEG/01.jpg"),
    require("../assets/homeweb/JPEG/02.jpg"),
    require("../assets/homeweb/JPEG/03.jpg"),
    require("../assets/homeweb/JPEG/04.jpg"),
    require("../assets/homeweb/JPEG/05.jpg"),
  ];

  const imagesMobile = [
    require("../assets/homemobile/01.jpg"),
    require("../assets/homemobile/02.jpg"),
    require("../assets/homemobile/03.jpg"),
    require("../assets/homemobile/04.jpg"),
    require("../assets/homemobile/05.jpg"),
  ];

  const images = window.innerWidth >= 768 ? imagesWeb : imagesMobile;

  // const images = [homeslide1, homeslide2, homeslid5, homeslid3, homeslid4];

  useEffect(() => {
    const handleScroll = () => {
      const scrollY = window.scrollY || document.documentElement.scrollTop;
      setShowBottomNavBar(scrollY <= 10);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentSlide((prevSlide) => (prevSlide + 1) % images.length);
    }, 4000);

    return () => clearInterval(interval);
  }, [images.length]);

  useEffect(() => {
    // Check if all images are loaded
    const checkAllImagesLoaded = () => {
      const allImagesLoaded = images.every((image) => {
        const img = new Image();
        img.src = image;
        return img.complete;
      });

      setImagesLoaded(allImagesLoaded);
    };

    // Add event listener for the 'load' event on each image
    images.forEach((image) => {
      const img = new Image();
      img.src = image;
      img.addEventListener("load", checkAllImagesLoaded);
    });

    return () => {
      // Remove event listener when the component unmounts
      images.forEach((image) => {
        const img = new Image();
        img.src = image;
        img.removeEventListener("load", checkAllImagesLoaded);
      });
    };
  }, [images]);

  const handlePrevSlide = () => {
    setCurrentSlide(
      (prevSlide) => (prevSlide - 1 + images.length) % images.length
    );
  };

  const handleNextSlide = () => {
    setCurrentSlide((prevSlide) => (prevSlide + 1) % images.length);
  };

  const slideStyle = {
    backgroundImage: `url(${images[currentSlide]})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundAttachment: "fixed",
    width: "100%",
    height: "100vh",
    backgroundColor: "black",
    transition: "background-image 1s ease-in-out",
    position: "relative",
  };

  return (
    <div className="slider-container">
      {!imagesLoaded ? (
        <div className="loader">
         <div className="loader-inner"></div>
        </div>
      ) : (
        <div className="slide" style={slideStyle}>
          <button className="slider-button left" onClick={handlePrevSlide}>
            &lt;
          </button>
          <button className="slider-button right" onClick={handleNextSlide}>
            &gt;
          </button>
        </div>
      )}
      <div className="bottom-navbar">
        <BottomNavBar isVisible={showBottomNavBar} />
      </div>
    </div>
  );
};

export default Slider;