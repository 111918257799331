import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import '../styles/topNav.css';

import { Button } from "@mui/material";
import logo from '../assets/logo/dreamLogo.png';
import InstagramIcon from '@mui/icons-material/Instagram';

const TopNavBar = () => {
  const [showSliderMenu, setShowSliderMenu] = useState(false);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const navigate = useNavigate();
  const menuRef = useRef(null);

  const handleHamburgerClick = () => {
    setShowSliderMenu(!showSliderMenu);
  };

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    const handleDocumentClick = (event) => {
      if (showSliderMenu) {
        // Check if the clicked element is inside the menu or the hamburger icon
        if (menuRef.current && !menuRef.current.contains(event.target) && !event.target.classList.contains('hamburger-menu')) {
          setShowSliderMenu(false);
        }
      }
    };

    window.addEventListener('resize', handleResize);
    document.addEventListener('click', handleDocumentClick);

    return () => {
      window.removeEventListener('resize', handleResize);
      document.removeEventListener('click', handleDocumentClick);
    };
  }, [showSliderMenu]);

  const navigateTo = (route) => {
    setShowSliderMenu(false);
    navigate(route);
  };

  const handleFollowClick = () => {
    window.open(
      "https://www.instagram.com/dreambox.photography?igsh=MW5rZDF0bW5lOTk2eg==",
      "_blank"
    );
  };
  return (
    <div className="top-nav-bar">
      {screenWidth <= 600 && (
        <div className="mobile-header">
          <div className="logo-container">
            <img src={logo} alt="Logo" className="logo-image" />
          </div>
          <div className="hamburger-menu" onClick={handleHamburgerClick}>
            &#9776;
          </div>
        </div>
      )}

      {screenWidth <= 600 && showSliderMenu && (
        <div className="slider-menu-container" ref={menuRef}>
          <div className="slider-menu-background"></div>
          <div className="slider-menu">
            <div className="close-button" onClick={() => setShowSliderMenu(false)}>
              &times;
            </div>
            <span className="slider-menu-item" onClick={() => navigateTo('/')}>Home</span>
            <span className="slider-menu-item" onClick={() => navigateTo('/ourStory')}>OUR STORY</span>
            <span className="slider-menu-item" onClick={() => navigateTo('/portrayals')}>PORTRAYALS</span>
            <span className="slider-menu-item" onClick={() => navigateTo('/films')}>FLIMS</span>
            <span className="slider-menu-item" onClick={() => navigateTo('/critiques')}>CRITIQUE</span>
            <span className="slider-menu-item" onClick={() => navigateTo('/contactUs')}>PROPOSALS</span>
            <span className="slider-menu-item" onClick={() => navigateTo('/contactUs')}>  <Button
                         onClick={handleFollowClick}
            >
              <InstagramIcon style={{fontSize:"40px" ,color: "#b89043"}} />
            </Button></span>
          </div>
        </div>
      )}

      {screenWidth > 600 && (
        <nav>
          <ul>
            <li style={{ marginTop: "15px" }} className="nav-item" onClick={() => navigateTo('/')}>Home</li>
            <li style={{ marginTop: "15px" }} className="nav-item" onClick={() => navigateTo('/ourStory')}>OUR STORY</li>
            <li style={{ marginTop: "15px" }} className="nav-item" onClick={() => navigateTo('/portrayals')}>PORTRAYALS</li>
            <li className="nav-item">
              <img src={logo} alt="Logo" className="logo-image" />
            </li>
            <li style={{ marginTop: "15px" }} className="nav-item" onClick={() => navigateTo('/films')}>FLIMS</li>
            <li style={{ marginTop: "15px" }} className="nav-item" onClick={() => navigateTo('/critiques')}>CRITIQUE</li>
            <li style={{ marginTop: "15px" }} className="nav-item" onClick={() => navigateTo('/contactUs')}>PROPOSALS</li>
          </ul>
        </nav>
      )}
    </div>
  );
};

export default TopNavBar;
