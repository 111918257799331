/** @format */

import React, { useEffect } from "react";
import "../../styles/projectsJour.css";
import { Link } from "react-router-dom";
import TopNavBar from "../../components/topNav";
import Footer from "../../components/footer";
import Tumbnail from "../../assets/tumbNails/project6.jpg";

const imageContextPrewed = require.context(
  "../../assets/06tharani _vimalesh/New folder",
  false,
  /\.(jpg)$/
);
const imageUrlsPrewed = imageContextPrewed.keys().map(imageContextPrewed);

const allImageUrls = [...imageUrlsPrewed];

const ProjectSix = () => {
  useEffect(() => {
    document.documentElement.scrollTop = 0;
  }, []);

  return (
    <div>
      <TopNavBar />

      <div className="proj-gallery-container">
        <div className="header-project">
          <img
            src={Tumbnail}
            alt="Full Page"
            className="proj-full-page-image"
          />
        </div>

        <div className="proj-gallery">
          {allImageUrls.map((imageUrl, index) => (
            <Link to="#" key={index} className="proj-gallery-link">
              <img
                src={imageUrl}
                alt={`Photo ${index + 1}`}
                className="proj-gallery-image"
              />
            </Link>
          ))}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default ProjectSix;
