/** @format */

import React from "react";
import Image1 from "../assets/services/Cd.png";
import Image2 from "../assets/services/TTr.png";
import "../styles/ourService.css";

const ServicesSection = () => {
  return (
    <div className="services-section">
      <div className="content-above-images">
        <h2
          className="centered-heading-services"
          style={{
            color: "#b89043",
            fontFamily: "Histeagin",
            fontWeight: "normal",
            marginTop: "100px",
          }}
        >
          OUR SERVICES
        </h2>
      </div>

      <div className="images-container">
        <div className="image-column">
          <img src={Image1} alt="Service 1" className="responsive-image-1" />
        </div>
        <div className="image-column">
          <img src={Image2} alt="Service 2" className="responsive-image-2" />
        </div>
      </div>
    </div>
  );
};

export default ServicesSection;
