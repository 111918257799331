/** @format */

// App.js
import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./App.css";
import Home from "./Pages/home";
import Testimonials from "./Pages/testimonial";
import FounderPage from "./Pages/founder.js";
import FilmComponent from "./Pages/flimes";
import JournalsPage from "./Pages/journals";
import ProjectOne from "./components/Journals/project1";
import ProjectTwo from "./components/Journals/project2";
import ProjectThree from "./components/Journals/project3";
import ProjectFour from "./components/Journals/project4";
import ProjectFive from "./components/Journals/project5";
import ProjectSix from "./components/Journals/project6";
import ProjectSeven from "./components/Journals/project7";
import ProjectEight from "./components/Journals/project8";

import ProposalPage from "./components/proposal";

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/critiques" element={<Testimonials />} />
        <Route path="/ourStory" element={<FounderPage />} />
        <Route path="/films" element={<FilmComponent />} />
        <Route path="/portrayals" element={<JournalsPage />} />
        <Route path="/projectOne" element={<ProjectOne />} />
        <Route path="/projectTwo" element={<ProjectTwo />} />
        <Route path="/projectThree" element={<ProjectThree />} />
        <Route path="/projectFour" element={<ProjectFour />} />
        <Route path="/projectFive" element={<ProjectFive />} />
        <Route path="/projectSix" element={<ProjectSix />} />
        <Route path="/projectSeven" element={<ProjectSeven />} />
        <Route path="/projectEight" element={<ProjectEight />} />

        <Route path="/contactUs" element={<ProposalPage />} />
      </Routes>
    </Router>
  );
};

export default App;
